.project-filter-list {
  border-radius: unset !important;
  /* border: unset !important; */
  border-bottom: 1px solid #e4e4e4;
}
.project-filter-list.disabled {
  /* pointer-events: none;
  background-color: #e4e4e4;
  cursor: not-allowed; */
}

.section-item-border p,
.project-filter-list button {
  padding: 0 24px;
}

.project-filter-list section p {
  display: flex;
  align-items: center;
}

.section-item-border:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}

.section-item-border:first-child {
  border-top: 1px solid #e4e4e4;
}

.arrow-icon-inactive path {
  stroke: #c7c7c7;
}
