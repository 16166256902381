.full-screen .tippy-box[data-animation="shift-left"][data-state="hidden"] {
  opacity: 0;
  transform: translateX(100%);
  height: calc(100 * var(--vh));
  width: 100vw;
  background-color: #f7f7f7;
}
.full-screen .tippy-box {
  height: calc(100 * var(--vh));
  background-color: #f7f7f7;
  width: 100vw;
  max-width: unset !important;
  border-radius: 0;
}
.full-screen div[data-tippy-root] {
  width: 100vw;
  transform: translate3d(0, 0, 0) !important;
  max-width: unset !important;
  position: fixed !important;
  z-index: 1000000 !important;
}

.content-screen > div[data-tippy-root] {
  width: 100vw;
  transform: translate3d(0, 0, 0) !important;
  max-width: unset !important;
  z-index: 9999 !important;
}

.content-screen .tippy-box {
  max-width: unset !important;
  width: 100vw;
}

.content-screen .tippy-box[data-animation="shift-left"][data-state="hidden"] {
  opacity: 0;
  transform: translateX(100%);
}

.content-screen .tippy-content {
  height: calc(100 * var(--vh) - 52px);
}

.content-screen .tippy-content > div {
  height: 100%;
}

.content-screen .tippy-content,
.full-screen .tippy-content,
.conversation-mobile-tippy .tippy-content {
  padding: 0;
}
.build-conversation > div[data-tippy-root] {
  z-index: 9997 !important;
}

.reset-tippy .tippy-box {
  width: auto;
}

.reset-tippy .tippy-content {
  height: auto;
}
.reset-tippy .tippy-content > div {
  height: auto;
}
.reset-tippy .tippy-box[data-animation="shift-right"][data-state="hidden"] {
  transform: unset !important;
}
.reset-tippy > div[data-tippy-root] {
  width: auto;
  /* transform: translate3d(0, 0, 0) !important; */
  max-width: unset !important;
  /* z-index: 9999 !important; */
}
.conversation-mobile-tippy div[data-tippy-root] {
  /* width: 100vw; */
  /* transform: translate3d(0, 0, 0) !important; */
  transform: translate3d(0px, 109px, 0px) !important;
  /* max-width: unset !important; */
  /* position: fixed !important; */
}

.conversation-mobile-tippy .tippy-content {
  height: auto;
  background-color: white;
}
.conversation-mobile-tippy .tippy-box {
  max-width: unset !important;
  width: 100vw;
}

.conversation-mobile-tippy2 div[data-tippy-root] {
  /* width: 100vw; */
  /* transform: translate3d(0, 0, 0) !important; */
  transform: translate3d(0px, 129px, 0px) !important;
  /* max-width: unset !important; */
  /* position: fixed !important; */
}

.conversation-mobile-tippy2 .tippy-content {
  height: auto;
  background-color: white;
}
.conversation-mobile-tippy2 .tippy-box {
  max-width: unset !important;
  width: 100vw;
}
