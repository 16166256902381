.simple-table th {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  border-bottom: 1px solid #e4e4e4;
  padding: 12px 6px;
  min-width: 60px;
  white-space: nowrap;
}
.simple-table td {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  padding: 12px 6px;
}
.simple-table tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}

.storage-usage-table {
  background-color: #ffffff;
}
.storage-usage-table th {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  border-bottom: 1px solid #e4e4e4;
  padding: 12px;
  min-width: 60px;
  white-space: nowrap;
}
.storage-usage-table td {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  padding: 12px;
}
.storage-usage-table tbody tr.tr-primary {
  background-color: #ffffff;
}
.storage-usage-table tbody tr.tr-secondary {
  background-color: #f7f7f7;
}
.storage-usage-table tbody tr {
  border-top: 1px solid var(--backgrounds-stroke, #e4e4e4);
}
.storage-usage-table th:first-child {
  padding-left: 32px;
}
.storage-usage-table tbody tr td:last-child {
  justify-content: end;
}
