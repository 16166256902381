.setting-area {
  min-height: calc(100vh - 88px);
  position: relative;
}
.setting-area .actions {
  position: absolute;
  bottom: 24px;
  width: calc(100% - 48px);
  right: 24px;
  left: 24px;
}
@media only screen and (max-width: 991px) {
  .setting-area {
    min-height: calc(100vh - 140px);
  }
}
