.hover-btn-has-icon:hover {
  background-color: #0a5af5;
}
.hover-btn-has-icon:hover p {
  color: #ffffff;
}
.hover-btn-has-icon:hover svg path[stroke] {
  stroke: #ffffff;
}
.disabled-btn-has-icon {
  background-color: #c7c7c7;
  opacity: 0.3;
}
.disabled-btn-has-icon p {
  color: #111111;
}

.disabled-btn-has-icon svg path[stroke] {
  stroke: #111111;
}

.bg-btn-yellow {
  background: linear-gradient(180deg, #ffd27a 0%, #fdbe44 100%);
  color: #111111;
}
.bg-btn-yellow:hover {
  background: linear-gradient(180deg, #ffc451 0%, #f7ac1b 100%);
}
.bg-btn-disable {
  background: #f7f7f7;
  color: #c7c7c7;
}
.bg-btn-white {
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  color: #7a7a7a;
}
.bg-btn-white:hover {
  background: #f1f1f1;
  color: #111111;
}
.bg-btn-black {
  background: linear-gradient(180deg, #3e3e3e 0%, #111111 100%);
  color: #ffffff;
}
.bg-btn-black:hover {
  background: linear-gradient(180deg, #3e3e3e 0%, #111111 100%);
}
.bg-btn-red {
  background: linear-gradient(180deg, #fb6e6e 0%, #ea4545 100%);
  color: #ffffff;
}
.bg-btn-red:hover {
  background: linear-gradient(180deg, #f56666 0%, #cd3b3b 100%);
}

/* only approve to multi button inline */
@media screen and (min-width: 992px) {
  .multi-button + * {
    margin-left: 12px;
  }
}
